import loadable from "@loadable/component";
import clsx from "clsx";

const Connector = loadable(() => import("@micado-digital/react-form/Connector"));
const Container = loadable(() => import("@Components/views/Container"));
const Form = loadable(() => import("@micado-digital/react-form"));
const ReactCopyrights01 = loadable(() =>
	import("@micado-digital/react-copyrights/ReactCopyrights01")
);
const ReactDropDown01 = loadable(() =>
	import("@micado-digital/react-dropdown/ReactDropDown01")
);
const ReactFile01 = loadable(() => import("@micado-digital/react-file/ReactFile01"));
const ReactGallery01 = loadable(() => import("@micado-digital/react-gallery/ReactGallery01"));
const ReactGallery04 = loadable(() => import("@micado-digital/react-gallery/ReactGallery04"));
const ReactHeadline01 = loadable(() =>
	import("@micado-digital/react-headline/ReactHeadline01")
);
const ReactHTML = loadable(() => import("@micado-digital/react-html/ReactHTML"));
const ReactImageText03 = loadable(() =>
	import("@micado-digital/react-imagetext/ReactImageText03")
);
const ReactList01 = loadable(() => import("@micado-digital/react-list/ReactList01"));
const ReactReferences03 = loadable(() =>
	import("@micado-digital/react-references/ReactReferences03")
);
const ReactSingleImage04 = loadable(() =>
	import("@micado-digital/react-singleimage/ReactSingleImage04")
);
const ReactSVGIcon = loadable(() => import("@micado-digital/react-svg-icon/ReactSVGIcon"));
const ReactTable01 = loadable(() => import("@micado-digital/react-table/ReactTable01"));
const ReactText01 = loadable(() => import("@micado-digital/react-text/ReactText01"));
const ReactVideo02 = loadable(() => import("@micado-digital/react-video/ReactVideo02"));
const ReactLegacy = loadable(() => import("@micado-digital/react-legacy/ReactLegacy"));
const ImageHeadlineCombo = loadable(() => import("@Components/Elements/ImageHeadlineCombo"));
const SingleTeaser = loadable(() => import("@Components/Elements/SingleTeaser"));
const TeaserSlider = loadable(() => import("@Components/Elements/TeaserGroup/Slider"));
const TeaserSliderSingle = loadable(() =>
	import("@Components/Elements/TeaserGroup/SliderSingle")
);
const Partner = loadable(() => import("@Components/Elements/TeaserGroup/Partner"));
const RoomList = loadable(() => import("@Components/Elements/TeaserGroup/RoomList"));
const WeatherWebcam = loadable(() => import("@Components/Elements/WeatherWebcam"));
const ReactWeather04 = loadable(() => import("@micado-digital/react-weather/ReactWeather04"));

const getJSXElement = (element, lang, l, isMobile, sysLink) => {
	const { REACT_APP_PATH } = process.env;
	const { anchor, id, tag } = element;

	switch (tag) {
		case "basic-headline": {
			const { text, variant = 0 } = element;

			let config = {};

			switch (variant) {
				case 0: {
					config = {
						component: "h1",
						variants: {
							xs: "display-small",
							md: "display-large"
						}
					};
					break;
				}
				case 1: {
					config = {
						component: "h2",
						variants: {
							xs: "headline-medium",
							md: "headline-large"
						}
					};
					break;
				}
				case 2: {
					config = {
						component: "h3",
						variants: {
							xs: "headline-small",
							md: "headline-medium"
						}
					};
					break;
				}
				case 3: {
					config = {
						component: "h4",
						variant: "headline-small"
					};
					break;
				}
				case 4: {
					config = {
						component: "h5",
						variant: "title-large"
					};
					break;
				}
				case 5: {
					config = {
						component: "h6",
						variant: "title-medium"
					};
					break;
				}
				case 6: {
					config = {
						component: "p",
						variant: "title-small"
					};
					break;
				}
				default: {
					break;
				}
			}

			return (
				<Container
					anchor={anchor}
					className={`mco-view-component-container-basic-headline--variant-${variant + 1}`}
					key={id}
					tag={tag}
				>
					<ReactHeadline01
						component={config?.component}
						text={text}
						variant={config?.variant}
						variants={config?.variants}
					/>
				</Container>
			);
		}

		case "basic-text": {
			const { text, variant } = element;

			return (
				<Container
					anchor={anchor}
					className={clsx({
						"mco-view-component-container-basic-text-variant--two-columns": variant === 1
					})}
					key={id}
					tag={tag}
				>
					<ReactText01
						ButtonProps={{
							endIcon: <ReactSVGIcon src="/img/icons/chevron-down.svg" size={20} />,
							variant: "text"
						}}
						hideMobile={true}
						text={text}
						variant="body-medium"
					/>
				</Container>
			);
		}

		case "basic-list": {
			const { textcontent, title, variant } = element;

			return (
				<Container
					anchor={anchor}
					background
					className={`mco-view-component-container-basic-list-variant--${variant}`}
					maxWidth="none"
					noPadding
					key={id}
					tag={tag}
				>
					<Container>
						<ReactList01
							headlineVariants={{ xs: "headline-medium", md: "headline-large" }}
							icon={<ReactSVGIcon color="primary" src="/img/icons/check.svg" size={24} />}
							textcontent={textcontent}
							textVariant="body-medium"
							title={title}
							variant={variant === 2 ? 1 : variant}
						/>
					</Container>
				</Container>
			);
		}

		case "basic-table": {
			const { addition, title, textcontent, variant } = element;

			const newTableArray = [];

			for (const entries of textcontent?.items) {
				const rows = entries?.items;
				const columns = rows.map(column => {
					const columnObj = {
						text: column?.text,
						align: column?.["attr-align"],
						width: column?.["attr-width"]
					};
					return columnObj;
				});

				newTableArray.push(columns);
			}

			return (
				<Container anchor={anchor} background maxWidth="none" key={id} tag={tag}>
					<Container>
						<ReactTable01
							addition={addition}
							headlineVariant="headline-large"
							items={newTableArray}
							title={title}
							sticky={variant === 1 ? true : false}
						/>
					</Container>
				</Container>
			);
		}

		case "basic-links": {
			const { elements, title } = element;

			return (
				<Container anchor={anchor} key={id} tag={tag}>
					<ReactReferences03
						buttonVariant="outlined"
						elements={elements}
						endIcon={
							<ReactSVGIcon color="primary" src="/img/icons/chevron-right.svg" size={24} />
						}
						hasIcon={false}
						headlineVariants={{ xs: "headline-large" }}
						linkVariant="label-medium"
						title={title}
					/>
				</Container>
			);
		}

		case "basic-video": {
			const { media, title, text } = element;

			return (
				<Container anchor={anchor} key={id} tag={tag}>
					<ReactVideo02
						additionVariant="body-small"
						titleVariants={{ md: "headline-large" }}
						media={media[0]}
						mediaFormats={{
							xs: "video-mobile",
							sm: "video"
						}}
						mediaPath={REACT_APP_PATH}
						title={title}
						text={text}
					/>
				</Container>
			);
		}

		case "basic-html": {
			const { text } = element;

			return (
				<Container anchor={anchor} key={id} tag={tag}>
					<ReactHTML key={id} html={text} />
				</Container>
			);
		}

		case "basic-download-group": {
			const { children } = element;

			return (
				<Container anchor={anchor} key={id} tag={tag}>
					{children?.map(item => getJSXElement(item))}
				</Container>
			);
		}

		case "basic-download": {
			const { media, title } = element;

			return (
				<Container anchor={anchor} key={id} tag={tag}>
					<ReactFile01 media={media} title={title} />
				</Container>
			);
		}

		case "basic-copyrights": {
			return (
				<Container anchor={anchor} key={id} tag={tag}>
					<ReactCopyrights01 />
				</Container>
			);
		}

		case "basic-imprint": {
			return (
				<Container anchor={anchor} key={id} tag={tag}>
					<ReactLegacy lang={lang} logoIdentifier="logo" />
				</Container>
			);
		}

		case "basic-dropdown-group": {
			const { children } = element;

			return (
				<Container anchor={anchor} key={id} tag={tag}>
					<ReactDropDown01
						icon={<ReactSVGIcon color="primary" src="/img/icons/dropdown.svg" size={24} />}
						option={0}
						title={element?.children?.[0]?.title}
					>
						<>
							{children
								?.filter(item => item.tag !== "basic-dropdown")
								?.map(item => getJSXElement(item))}
						</>
					</ReactDropDown01>
				</Container>
			);
		}

		case "basic-singleimage": {
			const { addition, media, option2, reference, title, text } = element;

			return (
				<Container anchor={anchor} key={id} maxWidth={2560} noPadding tag={tag}>
					<ReactSingleImage04
						addition={addition}
						media={media ? media?.[0] : {}}
						mediaFormats={{
							xs: "singleimage-mobile",
							sm: "singleimage"
						}}
						mediaPath={REACT_APP_PATH}
						option2={option2}
						reference={reference}
						text={text}
						title={title}
						titleVariants={{ xs: "headline-small", md: "headline-large" }}
					/>
				</Container>
			);
		}

		case "basic-singleteaser": {
			const { id, teaser, text, title, variant, teaser: { media } = {} } = element;
			const teaserData = {
				id: id,
				link: teaser?.link,
				media: teaser?.media,
				text: text ? text : teaser?.text,
				title: title ? title : teaser?.title
			};

			return (
				<Container anchor={anchor} key={id} noPadding tag={tag}>
					<SingleTeaser
						teaser={teaserData}
						lang={lang}
						media={media?.[0]}
						mediaPath={REACT_APP_PATH}
						mediaFormats={{
							xs: "teaser-mobile",
							sm: "teaser-sub",
							md: "teaser"
						}}
						variant={variant}
					/>
				</Container>
			);
		}

		case "extension-external-singleteaser": {
			const { elements, id, textcontent, variant } = element;

			const title = textcontent?.items?.find(item => item?.name === "valTitle")?.text;
			const text = textcontent?.items?.find(item => item?.name === "valText")?.text;
			const link = textcontent?.items?.find(item => item?.name === "valUrl")?.text;
			const media = elements?.find(e => e.media)?.media;

			const teaserData = {
				id: id,
				link: link,
				linkTarget: "_blank",
				media: media,
				text: text,
				title: title
			};

			return (
				<Container anchor={anchor} key={id} noPadding tag={tag}>
					<SingleTeaser
						teaser={teaserData}
						lang={lang}
						media={media?.[0]}
						mediaPath={REACT_APP_PATH}
						mediaFormats={{
							xs: "teaser-mobile",
							sm: "teaser-sub",
							md: "teaser"
						}}
						variant={variant}
					/>
				</Container>
			);
		}

		case "basic-teaser": {
			const {
				addition2: externalURL,
				id,
				teasergroup: { items = [] } = {},
				reference: { pageName } = {},
				option4: target = 0,
				text: linkTitle = "",
				title,
				variant = 0
			} = element;

			// target 0 = "_top", target 1 = "_blank"

			let link = "";

			if (externalURL) {
				link = externalURL;
			} else if (pageName) {
				link = pageName;
			}

			const targetBlank = target === 1 ? true : false;

			if (variant === 1) {
				return (
					<Container anchor={anchor} key={id} tag={tag}>
						<TeaserSliderSingle
							buttonLabel={l("teaser.button")}
							items={items}
							lang={lang}
							link={link}
							linkTitle={linkTitle}
							mobileSlider
							mediaFormats={{ xs: "teaser-mobile" }}
							targetBlank={targetBlank}
							title={title}
						/>
					</Container>
				);
			}

			if (variant === 2) {
				return (
					<Container key={id} tag={tag} anchor={anchor}>
						<RoomList additionFields headline={title} lang={lang} items={items} />
					</Container>
				);
			}

			if (variant === 3) {
				return (
					<Container key={id} tag={tag} anchor={anchor}>
						<Partner
							headline={title}
							lang={lang}
							mediaFormats={{ xs: "teaser-partner" }}
							items={items}
						/>
					</Container>
				);
			}

			return (
				<Container anchor={anchor} key={id} tag={tag}>
					<TeaserSlider
						buttonLabel={l("teaser.button")}
						items={items}
						lang={lang}
						link={link}
						linkTitle={linkTitle}
						mobileSlider
						mediaFormats={{ xs: "teaser-mobile" }}
						targetBlank={targetBlank}
						title={title}
					/>
				</Container>
			);
		}

		case "basic-imagetext": {
			const { addition2, media, option, text, title } = element;

			return (
				<Container anchor={anchor} key={id} noPadding tag={tag}>
					<ReactImageText03
						addition={title}
						additionVariant="body-small"
						headlineVariants={{
							xs: "display-small",
							lg: "display-large"
						}}
						media={media?.[0]}
						mediaFormats={{
							xs: "imagetext-combi-mobile",
							md: "imagetext-combi"
						}}
						mediaPath={REACT_APP_PATH}
						title={addition2}
						text={text}
						textVariant="body-medium"
						variant={option}
					/>
				</Container>
			);
		}

		case "basic-gallery": {
			const { elements, variant } = element;

			return (
				<Container anchor={anchor} key={id} tag={tag}>
					{variant !== 1 ? (
						<ReactGallery01
							elements={elements}
							mediaFormats={{
								xs: "gallery-preview"
							}}
							overlayMediaFormats={{
								xs: "gallery"
							}}
						/>
					) : (
						<ReactGallery04
							elements={elements}
							mediaFormats={{
								xs: "gallery-preview"
							}}
							navNext="/img/icons/chevron-right.svg"
							navPrev="/img/icons/chevron-left.svg"
							overlayMediaFormats={{
								xs: "gallery"
							}}
							showPagination={true}
							showNavigation={true}
							SwiperProps={{
								breakpoints: {
									0: {
										slidesPerView: 1.1,
										spaceBetween: 16
									},
									960: {
										slidesPerView: 3,
										spaceBetween: 16
									},
									1280: {
										slidesPerView: 3,
										spaceBetween: 24
									}
								},
								centeredSlides: true,
								loop: true
							}}
						/>
					)}
				</Container>
			);
		}

		case "basic-form": {
			const { elements } = element;

			return (
				<Container anchor={anchor} key={id} tag={tag}>
					<Form action={`${REACT_APP_PATH}/submitform.json.api`} method="POST">
						<Connector formElements={elements} formID={id} />
					</Form>
				</Container>
			);
		}

		case "extension-image-headline-combo": {
			const { elements, textcontent: { items } = {} } = element;

			return (
				<Container anchor={anchor} key={id} noPadding tag={tag}>
					<ImageHeadlineCombo elements={elements} items={items} />
				</Container>
			);
		}

		case "extension-weather-webcam": {
			const { textcontent: { items } = {} } = element;
			const station = items?.find(item => item?.name === "valStation")?.text;
			const webcam = items?.find(item => item?.name === "valWebcam")?.text;
			const webcamTitle = items?.find(item => item?.name === "valWebcamTitle")?.text;

			return (
				<Container
					anchor={anchor}
					className="mco-collapsing"
					key={id}
					maxWidth="none"
					noPadding
					tag={tag}
				>
					<WeatherWebcam
						lang={lang}
						station={station}
						webcam={webcam}
						webcamTitle={webcamTitle}
					/>
				</Container>
			);
		}

		case "extension-weather": {
			const { textcontent: { items } = {} } = element;
			const stations = items?.find(item => item?.name === "valStation")?.text;

			return (
				<Container anchor={anchor} key={id} tag={tag}>
					<ReactWeather04
						headline={stations === "3" ? "Zell am See" : "Neukirchen am Großvenediger"}
						headlineVariant="h6"
						lang={lang}
						days={"0|1|2"}
						stations={stations}
						url={`${REACT_APP_PATH}/Micado.Tourism.Web/Micado.Tourism.Web.IO.Api.WeatherApi/GetDetailsAsJson.api`}
						skeletonItems={3}
					/>
				</Container>
			);
		}

		default:
			if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
				console.warn("missing element:", element);
			}
			return null;
	}
};

export default getJSXElement;
