import React, { useContext, useEffect } from "react";
import clsx from "clsx";
import PageContext from "@PageContext";
import useFindMenuGroup from "@micado-digital/react-ui/hooks/useFindMenuGroup";
import duplicateParentAsFirstChild from "@micado-digital/react-ui/utils/duplicateParentAsFirstChild";
import Level1 from "./Level1";

import styles from "./DesktopMenu.styles";

const DesktopMenu = () => {
	const css = styles();
	const [pageData] = useContext(PageContext);
	const { lang } = pageData;
	const menuItems = useFindMenuGroup("Hauptmenü");

	useEffect(() => {
		duplicateParentAsFirstChild(menuItems);
	}, [menuItems, lang]);

	return (
		<div className={clsx(css.desktopMenu, "mco-view-component-header-desktop-menu")}>
			<Level1 items={menuItems} />
		</div>
	);
};

export default DesktopMenu;
