import makeStyles from "@material-ui/styles/makeStyles";

const styles = makeStyles(theme => ({
	lang: {
		alignItems: "center",
		border: "1px solid #FFFFFF",
		color: "#FFFFFF",
		cursor: "pointer",
		display: "flex",
		height: theme.spacing(5),
		justifyContent: "center",
		position: "relative",
		textTransform: "uppercase",
		transition: "border-color 250ms ease, color 250ms ease",
		width: theme.spacing(5),
		zIndex: 90
	}
}));

export default styles;
