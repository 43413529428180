import React, { Suspense, useEffect } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import createTheme from "@material-ui/core/styles/createTheme";
import { alpha } from "@material-ui/core/styles/";
import CssBaseline from "@material-ui/core/CssBaseline";
import ThemeProvider from "@material-ui/styles/ThemeProvider";
import { theme as customTheme } from "./themes/project";
import CSSProperties from "./utils/CSSProperties";
import WebPageData from "@micado-digital/react-ui/components/WebPageData";
import Ui from "@micado-digital/react-ui/components/Ui";
import Views from "./views";
import "./css/index.css";
import mcoConfig from "./mco.config.json";

import {
	applyPolyfills as ialApplyPolyfills,
	defineCustomElements as ialDefineCustomElements
} from "@micado-digital/micado-ial/loader";

const theme = createTheme(customTheme);

const App = () => {
	useEffect(() => {
		CSSProperties(theme, alpha);
	}, []);

	return (
		<ThemeProvider theme={theme}>
			<Suspense fallback={<></>}>
				<CssBaseline />
				<BrowserRouter>
					<Ui settings={mcoConfig}>
						<WebPageData>
							<Routes>
								<Route path="/*" element={<Views />} />
							</Routes>
						</WebPageData>
					</Ui>
				</BrowserRouter>
			</Suspense>
		</ThemeProvider>
	);
};

ReactDOM.render(<App />, document.getElementById("root"));

ialApplyPolyfills().then(() => {
	ialDefineCustomElements(window);
});
