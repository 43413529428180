import makeStyles from "@material-ui/styles/makeStyles";

const styles = ({ headerHeight }) => {
	return makeStyles(theme => ({
		animationWrapper: {
			position: "relative"
		},
		animation: {
			height: "65vh",
			minHeight: 300,
			maxHeight: 650,
			position: "relative",
			"&:after": {
				background:
					"linear-gradient(0deg, rgba(0, 0, 0, 0) 79%, rgba(0, 0, 0, 0.8) 100%), linear-gradient(180deg, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0.8) 100%)",
				bottom: 0,
				content: "''",
				display: "block",
				left: 0,
				position: "absolute",
				right: 0,
				top: 0,
				zIndex: 1
			},
			[theme.breakpoints.up("lg")]: {
				maxHeight: 650,
				minHeight: 500
			},
			"& .mco-animation-01__bullets": {
				[theme.breakpoints.up("md")]: {
					bottom: "114px !important",
					flexDirection: "column !important",
					left: "44px !important",
					right: "auto !important"
				}
			},
			"& .mco-animation-01__bullet": {
				background: theme.palette.primary.light,
				borderRadius: "unset !important",
				height: "8px !important",
				opacity: "0.3 !important",
				transition: "opacity 250ms ease, width 250ms ease",
				width: "8px !important",
				[theme.breakpoints.up("md")]: {
					margin: "4px 0 !important"
				}
			},
			"& .mco-animation-01__bullet--selected": {
				background: theme.palette.secondary.main,
				opacity: "1 !important"
			},
			"& .mco-animation-01__arrow-next, .mco-animation-01__arrow-prev": {
				alignItems: "center",
				display: "flex",
				height: 40,
				left: 0,
				justifyContent: "center",
				width: 40,
				[theme.breakpoints.up("md")]: {
					height: 64,
					left: 16,
					width: 64
				}
			},
			"& .mco-animation-01__arrow-next": {
				left: "auto",
				right: 0,
				[theme.breakpoints.up("md")]: {
					left: "auto !important",
					right: 16 + "px !important"
				}
			},
			"& .mco-animation-01__arrow-prev": {
				[theme.breakpoints.up("md")]: {
					left: 16 + "px !important",
					right: "auto !important"
				}
			}
		},
		animationIntro: {
			height: "calc(var(--vh, 1vh) * 100)",
			maxHeight: "none !important",
			"&:after": {
				background:
					"linear-gradient(0deg, rgba(0, 0, 0, 0) 79%, rgba(0, 0, 0, 0.8) 100%), linear-gradient(180deg, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0.8) 100%)",
				bottom: 0,
				content: "''",
				display: "block",
				left: 0,
				position: "absolute",
				right: 0,
				top: 0,
				zIndex: 1
			}
		},
		animationTexts: {
			[theme.breakpoints.up("md")]: {
				alignItems: "flex-start",
				display: "flex",
				flexDirection: "column",
				justifyContent: "center",
				bottom: 96,
				left: 0,
				right: 0,
				margin: "0 auto",
				padding: theme.spacing(3, 19),
				maxWidth: 1328,
				position: "absolute",
				zIndex: 1
			},
			[theme.breakpoints.up(1440)]: {
				padding: theme.spacing(3)
			}
		},
		animatioTextsContent: {
			[theme.breakpoints.up("md")]: {
				color: theme.palette.common.white,
				maxWidth: 632,
				"& .text-1": {
					marginTop: theme.spacing(1),
					paddingBottom: theme.spacing(3.5),
					position: "relative",
					"&:after": {
						background: theme.palette.secondary.main,
						bottom: 0,
						content: "''",
						height: 4,
						left: 0,
						position: "absolute",
						width: 80
					}
				}
			}
		},
		animationIntroMobile: {
			height: `calc((var(--vh, 1vh) * 50) - ${headerHeight / 2}px)`
		},
		animationIntroMobileTexts: {
			background: theme.palette.grey[800],
			color: theme.palette.common.white,
			minHeight: `calc((var(--vh, 1vh) * 50) - ${headerHeight / 2}px)`,
			padding: theme.spacing(3, 4),
			position: "relative",
			[theme.breakpoints.up(375)]: {
				padding: theme.spacing(3, 6)
			},
			"& .text-1": {
				marginTop: theme.spacing(2),
				paddingBottom: theme.spacing(3.5),
				position: "relative",
				"&:after": {
					background: theme.palette.secondary.main,
					bottom: 0,
					content: "''",
					height: 4,
					left: 0,
					position: "absolute",
					width: 80
				}
			}
		}
	}))();
};

export default styles;
