import { useEffect, useState } from "react";

const useConsent = () => {
	const [consent, setConsent] = useState();

	useEffect(() => {
		const handleEvent = () => {
			setConsent(new Date().getTime());
		};

		document.body.addEventListener("mw-consent-accepted", handleEvent);
		document.body.addEventListener("mw-consent-customized", handleEvent);
		document.body.addEventListener("mw-consent-ignored", handleEvent);

		return () => {
			document.body.removeEventListener("mw-consent-accepted", handleEvent);
			document.body.removeEventListener("mw-consent-customized", handleEvent);
			document.body.removeEventListener("mw-consent-ignored", handleEvent);
		};
	}, []);

	return consent;
};

export default useConsent;
