import React, { useContext, useState, useMemo } from "react";
import clsx from "clsx";
import loadable from "@loadable/component";
import isEmpty from "lodash/isEmpty";
import UiContext from "@UiContext";
import PageContext from "@PageContext";
import useTheme from "@material-ui/core/styles/useTheme";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import useScrolling from "../../../hooks/useScrolling";
import Container from "../Container";
import Logo from "./Logo";
import Lang from "./Lang";
import Hamburger from "./Hamburger";
import MobileMenu from "./MobileMenu";
import DesktopMenu from "./DesktopMenu";
import VoucherBar from "./VoucherBar";

import styles from "./Header.styles";

const AnchorMenu = loadable(() => import("../AnchorMenu"));

const Header = () => {
	const css = styles();
	const [ui] = useContext(UiContext);
	const { desktopMenuOpen, mobileMenuOpen } = ui;
	const [pageData] = useContext(PageContext);
	const { elements = [], lang, layoutVariant } = pageData;
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
	const scrollPosition = useScrolling();
	const isScrollingTop = scrollPosition > 0;
	const isIntro = layoutVariant === "intro";
	const [voucherVisible, setVoucherVisible] = useState(true);

	const anchorItems = useMemo(() => {
		return elements?.filter(({ anchor }) => anchor?.visible)?.map(item => item?.anchor) ?? [];
	}, [elements]);

	const showBreadcrumb =
		!desktopMenuOpen && !mobileMenuOpen && isScrollingTop && !isEmpty(anchorItems);

	const handleClose = () => {
		setVoucherVisible(false);
	};

	return (
		<div
			className={clsx(css.header, "mco-view-component-header", {
				[css.headerScrolling]: isScrollingTop || !isIntro,
				"mco-view-component-header--scrolling": isScrollingTop,
				[css.headerIntro]: isIntro && !isScrollingTop,
				"mco-view-component-header--intro": isIntro && !isScrollingTop,
				[css.headerBreadcrumb]: showBreadcrumb,
				"mco-view-component-header--breadcrumb": showBreadcrumb,
				[css.headerWithVoucher]: isIntro && voucherVisible && !isScrollingTop
			})}
		>
			{isIntro && voucherVisible && (
				<VoucherBar handleClose={handleClose} isScrollingTop={isScrollingTop} lang={lang} />
			)}
			<Container className={clsx(css.container, "mco-view-component-header__container")}>
				<Logo />
				{!isMobile && <DesktopMenu />}
				<div className={clsx(css.icons, "mco-view-component-header__icons")}>
					{!isMobile && <Lang />}
					{isMobile && <Hamburger />}
				</div>
			</Container>
			{isMobile && mobileMenuOpen && <MobileMenu />}
			<AnchorMenu items={anchorItems} show={showBreadcrumb} />
		</div>
	);
};

export default Header;
