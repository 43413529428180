import React, { useContext, useEffect, useRef, useState } from "react";
import { createPortal } from "react-dom";
import loadable from "@loadable/component";
import clsx from "clsx";
import { motion, AnimatePresence } from "framer-motion";
import UiContext from "@UiContext";
import { disableBodyScroll, clearAllBodyScrollLocks } from "body-scroll-lock";
import PageContext from "@PageContext";
import useSetting from "@micado-digital/react-ui/hooks/useSettings";
import duplicateParentAsFirstChild from "@micado-digital/react-ui/utils/duplicateParentAsFirstChild";
import useLocals from "@micado-digital/react-ui/hooks/useLocals";
import localDE from "@localDE";
import localEN from "@localEN";
import localNL from "@localNL";

import styles from "./MobileMenu.styles";

const Booking = loadable(() => import("../Booking"));
const Level1 = loadable(() => import("./Level1"));
const Sidebar = loadable(() => import("./Sidebar"));

const MobileMenu = () => {
	const headerSettings = useSetting({
		identifier: "header",
		layout: true,
		layoutScroll: true,
		breakpoints: true
	});
	const { height: headerHeight, maxWidth } = headerSettings;
	const css = styles({ headerHeight, maxWidth });
	const [ui] = useContext(UiContext);
	const { mobileMenuOpen } = ui;
	const [pageData] = useContext(PageContext);
	const { lang, menuGroups } = pageData;
	const $list = useRef();
	const [level1Items, setLevel1Items] = useState([]);
	const [currentLevel1MenuID, setCurrentLevel1MenuID] = useState();

	const l = useLocals({
		lang: lang,
		res: {
			de: localDE,
			en: localEN,
			nl: localNL
		}
	});

	useEffect(() => {
		const mainmenu = menuGroups?.find(group => group.title === "Hauptmenü");
		const { items } = mainmenu || {};

		setLevel1Items(items);
	}, [menuGroups]);

	useEffect(() => {
		duplicateParentAsFirstChild(level1Items, l("menu.overview"));
	}, [level1Items, lang, l]);

	useEffect(() => {
		const node = el.current; // Create a variable to store the current value of the ref

		if (mobileMenuOpen) {
			document.body.appendChild(node);
		}

		return () => {
			if (node.parentElement) {
				node.parentElement.removeChild(node);
				clearAllBodyScrollLocks();
			}
		};
	}, [mobileMenuOpen]);

	useEffect(() => {
		disableBodyScroll($list.current);

		return () => {
			clearAllBodyScrollLocks();
		};

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const el = useRef(document.createElement("div"));
	el.current.className = clsx(
		css.mobilemenuWrapper,
		"mco-view-component-header-mobile-menu-wrapper"
	);

	const handleLevel1Menu = id => {
		if (currentLevel1MenuID === id) {
			return;
		}

		if (currentLevel1MenuID) {
			setCurrentLevel1MenuID(id);
		} else {
			setCurrentLevel1MenuID(id);
		}
	};

	if (!mobileMenuOpen) return null;

	return createPortal(
		<AnimatePresence>
			<motion.div
				className={clsx(css.mobilemenu, "mco-view-component-header-mobile-menu")}
				initial={{ x: "100%" }}
				animate={{ x: 0 }}
				exit={{ x: "100%" }}
				transition={{ type: "linear" }}
			>
				<div
					className={clsx(css.list, "mco-view-component-header-mobile-menu__list", {
						[css.listHasSubmenu]: currentLevel1MenuID
					})}
					ref={$list}
				>
					<Level1
						currentLevel1MenuID={currentLevel1MenuID}
						items={level1Items}
						setCurrentLevel1MenuID={handleLevel1Menu}
					/>
				</div>
				<Booking size="medium" />
				<Sidebar />
			</motion.div>
		</AnimatePresence>,
		el.current
	);
};

export default MobileMenu;
