import React, { useContext, useEffect, useState } from "react";
import clsx from "clsx";
import WebProfileContext from "@WebProfileContext";
import loadable from "@loadable/component";
import useSystemLinksExt from "@micado-digital/react-ui/hooks/useSystemLinksExt";
import useLocals from "@micado-digital/react-ui/hooks/useLocals";
import localDE from "@localDE";
import localEN from "@localEN";
import localNL from "@localNL";

import styles from "./VoucherBar.styles";

const Button = loadable(() => import("@material-ui/core/Button"));
const ReactLink = loadable(() => import("@micado-digital/react-link/ReactLink"));
const ReactSVGIcon = loadable(() => import("@micado-digital/react-svg-icon/ReactSVGIcon"));

const VoucherBar = ({ handleClose, isScrollingTop, lang }) => {
	const css = styles();
	const links = useSystemLinksExt();
	const { url } = links("booking") || {};
	const [profileData] = useContext(WebProfileContext);
	const { settings } = profileData;
	const [visible, setVisible] = useState(false);
	const l = useLocals({
		lang: lang,
		res: {
			de: localDE,
			en: localEN,
			nl: localNL
		}
	});

	useEffect(() => {
		setTimeout(() => {
			setVisible(true);
		}, 1000);
	}, []);

	const voucherCode = settings?.find(({ identifier }) => identifier === "voucher-code")?.value;

	return (
		<div
			className={clsx(css.voucherBar, "mco-view-component-header-voucher-bar", {
				[css.voucherBarScroll]: isScrollingTop,
				[css.voucherVisible]: visible
			})}
		>
			<div className={css.wrapper}>
				<span className={css.labelDirectBooking}>{l("header.discount.label")}</span>
				<span className={css.discount}>{l("header.discount.discount")}</span>
				<span className={css.addition}>{l("header.discount.addition")}</span>
				<div className={css.codeWrapper}>
					<div className={css.code}>
						<span>{l("header.discount.code")}</span>
						<strong>{voucherCode}</strong>
					</div>
					<ReactLink to={url}>
						<Button
							className={css.button}
							color="primary"
							size="small"
							variant="contained"
							endIcon={<ReactSVGIcon src="/img/icons/chevron-right.svg" size={20} />}
						>
							{l("header.discount.button")}
						</Button>
					</ReactLink>
				</div>
			</div>
			<div className={css.close} onClick={handleClose}>
				<ReactSVGIcon src="/img/icons/close.svg" size={20} />
			</div>
		</div>
	);
};

export default VoucherBar;
